/**
 * Add functions for Facebook
 * */

(function (wpm, $, undefined) {

	let fbUserData

	wpm.fbeventsJsUrl = () => {

		let searchParams = new URLSearchParams(window.location.search)

		if (searchParams.has("fbevents-version")) {
			return `https://connect.facebook.net/en_US/fbevents.js?v=${searchParams.get("fbevents-version")}`
		}

		return wpmDataLayer?.pixels?.facebook?.fbevents_js_url
	}

	wpm.loadFacebookPixel = () => {

		try {
			wpmDataLayer.pixels.facebook.loaded = true

			// @formatter:off
			!function(f,b,e,v,n,t,s)
			{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				n.callMethod.apply(n,arguments):n.queue.push(arguments)};
				if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
				n.queue=[];t=b.createElement(e);t.async=!0;
				t.src=v;s=b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t,s)}(window, document,'script',
				wpm.fbeventsJsUrl());
			// @formatter:on

			let data = {}

			// Add user identifiers to data,
			// and only if fbp was set
			if (wpm.isFbpSet() && wpm.isFbAdvancedMatchingEnabled()) {
				data = {...wpm.getUserIdentifiersForFb()}
			}

			// https://developers.facebook.com/docs/meta-pixel/advanced/#automatic-configuration
			// fbq("set", "autoConfig", false, wpmDataLayer.pixels.facebook.pixel_id)

			// Initialize the Facebook Pixel and send the first PageView event
			fbq("init", wpmDataLayer.pixels.facebook.pixel_id, data)

			if (wpmDataLayer?.pixels?.facebook?.mobile_bridge_app_id) {
				fbq("set", "mobileBridge", wpmDataLayer.pixels.facebook.pixel_id, wpmDataLayer?.pixels?.facebook?.mobile_bridge_app_id)
			}

			fbq("track", "PageView")

		} catch (e) {
			console.error(e)
		}
	}

	// https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching
	wpm.getUserIdentifiersForFb = () => {

		let data = {}

		// external ID
		if (wpmDataLayer?.user?.id?.raw) data.external_id = wpmDataLayer.user.id.raw
		if (wpmDataLayer?.order?.user_id) data.external_id = wpmDataLayer.order.user_id

		// email
		if (wpmDataLayer?.user?.email?.facebook) data.em = wpmDataLayer.user.email.facebook
		if (wpmDataLayer?.order?.billing_email_hashed) data.em = wpmDataLayer.order.billing_email_hashed

		// first name
		if (wpmDataLayer?.user?.first_name?.facebook) data.fn = wpmDataLayer.user.first_name.facebook
		if (wpmDataLayer?.order?.billing_first_name) data.fn = wpmDataLayer.order.billing_first_name.toLowerCase()

		// last name
		if (wpmDataLayer?.user?.last_name?.facebook) data.ln = wpmDataLayer.user.last_name.facebook
		if (wpmDataLayer?.order?.billing_last_name) data.ln = wpmDataLayer.order.billing_last_name.toLowerCase()

		// phone
		if (wpmDataLayer?.user?.phone?.facebook) data.ph = wpmDataLayer.user.phone.facebook
		if (wpmDataLayer?.order?.billing_phone) data.ph = wpmDataLayer.order.billing_phone.replace("+", "")

		// city
		if (wpmDataLayer?.user?.city?.facebook) data.ct = wpmDataLayer.user.city.facebook
		if (wpmDataLayer?.order?.billing_city) data.ct = wpmDataLayer.order.billing_city.toLowerCase().replace(/ /g, "")

		// state
		if (wpmDataLayer?.user?.state?.facebook) data.st = wpmDataLayer.user.state.facebook
		if (wpmDataLayer?.order?.billing_state) data.st = wpmDataLayer.order.billing_state.toLowerCase().replace(/[a-zA-Z]{2}-/, "")

		// postcode
		if (wpmDataLayer?.user?.postcode?.facebook) data.zp = wpmDataLayer.user.postcode.facebook
		if (wpmDataLayer?.order?.billing_postcode) data.zp = wpmDataLayer.order.billing_postcode

		// country
		if (wpmDataLayer?.user?.country?.facebook) data.country = wpmDataLayer.user.country.facebook
		if (wpmDataLayer?.order?.billing_country) data.country = wpmDataLayer.order.billing_country.toLowerCase()

		return data
	}

	wpm.getFbRandomEventId = () => (Math.random() + 1).toString(36).substring(2)

	wpm.getFbUserData = () => {

		/**
		 * We need to cache the FB user data for InitiateCheckout
		 * where getting the user data from the browser is too slow
		 * using wpm.getCookie().
		 *
		 * And we need the object merge because the ViewContent hit happens too fast
		 * after adding a variation to the cart because the function to cache
		 * the user data is too slow.
		 *
		 * But we can get the user_data using wpm.getCookie()
		 * because we don't move away from the page and can wait for the browser
		 * to get it.
		 *
		 * Also, the merge ensures that new data will be added to fbUserData if new
		 * data is being added later, like user ID, or fbc.
		 */

		fbUserData = {...fbUserData, ...wpm.getFbUserDataFromBrowser()}

		return fbUserData
	}

	wpm.isFbAdvancedMatchingEnabled = () => {
		if (wpmDataLayer?.pixels?.facebook?.advanced_matching) {
			return true
		}

		return false
	}

	wpm.setFbUserData = () => {
		fbUserData = wpm.getFbUserDataFromBrowser()
	}

	wpm.getFbUserDataFromBrowser = () => {

		let data = {}

		let fbp = wpm.getCookie("_fbp")
		if (wpm.isValidFbp(fbp)) {
			data.fbp = fbp
		}

		let fbc = wpm.getCookie("_fbc") || wpm.retrieveData("fbclid")
		if (wpm.isValidFbc(fbc)) {
			data.fbc = fbc
		}

		if (wpm.isFbAdvancedMatchingEnabled()) {
			if (wpmDataLayer?.user?.email?.facebook) data.em = wpmDataLayer.user.email.facebook
			if (wpmDataLayer?.user?.phone?.facebook) data.ph = wpmDataLayer.user.phone.facebook
			if (wpmDataLayer?.user?.first_name?.facebook) data.fn = wpmDataLayer.user.first_name.facebook
			if (wpmDataLayer?.user?.last_name?.facebook) data.ln = wpmDataLayer.user.last_name.facebook
			if (wpmDataLayer?.user?.city?.facebook) data.ct = wpmDataLayer.user.city.facebook
			if (wpmDataLayer?.user?.state?.facebook) data.st = wpmDataLayer.user.state.facebook
			if (wpmDataLayer?.user?.postcode?.facebook) data.zp = wpmDataLayer.user.postcode.facebook
			if (wpmDataLayer?.user?.country?.facebook) data.country = wpmDataLayer.user.country.facebook
			if (wpmDataLayer?.user?.id?.raw) data.external_id = wpmDataLayer.user.id.raw
		}

		if (navigator.userAgent) data.client_user_agent = navigator.userAgent

		// If a IPv6 address is available in the data store, add it to the user data
		if (wpm.retrieveData("ipv6")) data.client_ip_address = wpm.retrieveData("ipv6")

		return data
	}

	wpm.isFbpSet = () => {
		return !!wpm.getCookie("_fbp")
	}

	// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
	wpm.isValidFbp = fbp => {

		let re = new RegExp(/^fb\.[0-2]\.\d{13}\.\d{8,20}$/)

		return re.test(fbp)
	}

	// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
	wpm.isValidFbc = fbc => {

		let re = new RegExp(/^fb\.[0-2]\.\d{13}\.[\da-zA-Z_-]{8,}/)

		return re.test(fbc)
	}

	wpm.fbGetProductDataForCapiEvent = product => {
		return {
			content_type: "product",
			content_name: product.name,
			content_ids : [
				product.dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type],
			],
			value       : parseFloat(product.quantity * product.price),
			currency    : product.currency,
		}
	}

	wpm.facebookContentIds = () => {
		let prodIds = []

		Object.values(wpmDataLayer.order.items).forEach(item => {
			if (wpmDataLayer?.shop?.variations_output && 0 !== item.variation_id) {
				prodIds.push(String(wpmDataLayer.products[item.variation_id].dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type]))
			} else {
				prodIds.push(String(wpmDataLayer.products[item.id].dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type]))
			}
		})

		return prodIds
	}

	wpm.trackCustomFacebookEvent = (eventName, customData = {}) => {
		try {
			if (!wpmDataLayer?.pixels?.facebook?.loaded) return

			let eventId = wpm.getFbRandomEventId()

			fbq("trackCustom", eventName, customData, {
				eventID: eventId,
			})

			let payload = {
				facebook: {
					event_name      : eventName,
					event_id        : eventId,
					user_data       : wpm.getFbUserData(),
					event_source_url: window.location.href,
				},
			}

			// if customData not empty, add it to the payload
			if (Object.keys(customData).length) {
				payload.facebook.custom_data = customData
			}

			wpm.sendEventPayloadToServer(payload)

		} catch (e) {
			console.error(e)
		}
	}

	wpm.fbGetContentIdsFromCart = () => {

		let content_ids = []

		for (const key in wpmDataLayer.cart) {
			content_ids.push(wpmDataLayer.products[key].dyn_r_ids[wpmDataLayer.pixels.facebook.dynamic_remarketing.id_type])
		}

		return content_ids
	}

	/**
	 * Check if Facebook Pixel is allowed to fire
	 *
	 * @returns {boolean}
	 */
	wpm.canFireFbq = () => {

		// If the Facebook Pixel is not loaded, return false
		if (!wpmDataLayer?.pixels?.facebook?.loaded) return false

		// If the user has not given consent for marketing, return false
		if (!wpm.consent.categories.get().marketing) return false

		return true
	}

	/**
	 * Check if Facebook Pixel is not allowed to fire
	 *
	 * @returns {boolean}
	 */
	wpm.canNotFireFbq = () => {
		return !wpm.canFireFbq()
	}

}(window.wpm = window.wpm || {}, jQuery));
