/**
 * All event listeners
 *
 * https://developers.facebook.com/docs/meta-pixel/reference
 * */

// Load pixel event
jQuery(document).on("pmw:load-pixels", () => {

	if (
		wpmDataLayer?.pixels?.facebook?.pixel_id
		&& !wpmDataLayer?.pixels?.facebook?.loaded
		&& !wpm.doesUrlContainPatterns(wpmDataLayer?.pixels?.facebook?.exclusion_patterns)
	) {
		if (wpm.consent.canPixelBeFired("marketing", "Facebook")) wpm.loadFacebookPixel()
	}
})

// AddToCart event
// https://developers.facebook.com/docs/meta-pixel/reference
jQuery(document).on("pmw:s2s:add-to-cart", (event, payload) => {

	try {
		if (wpm.canNotFireFbq()) return

		fbq("track", "AddToCart", payload.facebook.custom_data, {
			eventID: payload.facebook.event_id,
		})
		pmw.console.log("Facebook Pixel: AddToCart event sent", payload.facebook)

	} catch (error) {
		console.error(error)
	}
})

// InitiateCheckout event
// https://developers.facebook.com/docs/meta-pixel/reference
jQuery(document).on("pmw:s2s:begin-checkout", (event, payload) => {

	try {
		if (wpm.canNotFireFbq()) return

		fbq("track", "InitiateCheckout", payload.facebook.custom_data, {
			eventID: payload.facebook.event_id,
		})
		pmw.console.log("Facebook Pixel: InitiateCheckout event sent", payload.facebook)

	} catch (error) {
		console.error(error)
	}
})

// AddPaymentInfo event
// https://developers.facebook.com/docs/meta-pixel/reference
jQuery(document).on("pmw:s2s:add-payment-info", (event, payload) => {

	try {
		if (wpm.canNotFireFbq()) return

		const eventName = "AddPaymentInfo"

		fbq("track", eventName, payload.facebook.custom_data, {
			eventID: payload.facebook.event_id,
		})
		pmw.console.log(`Facebook Pixel: ${eventName} event sent`, payload.facebook)

	} catch (error) {
		console.error(error)
	}
})

// AddToWishlist event
// https://developers.facebook.com/docs/meta-pixel/reference
jQuery(document).on("pmw:s2s:add-to-wishlist", (event, payload) => {

	try {
		if (wpm.canNotFireFbq()) return

		fbq("track", "AddToWishlist", payload.facebook.custom_data, {
			eventID: payload.facebook.event_id,
		})
		pmw.console.log("Facebook Pixel: AddToWishlist event sent", payload.facebook)

	} catch (error) {
		console.error(error)
	}
})

// ViewContent event
// https://developers.facebook.com/docs/meta-pixel/reference
jQuery(document).on("pmw:s2s:view-item", (event, payload) => {

	try {
		if (wpm.canNotFireFbq()) return

		fbq("track", "ViewContent", payload.facebook.custom_data, {
			eventID: payload.facebook.event_id,
		})
		pmw.console.log("Facebook Pixel: ViewContent event sent", payload.facebook)

	} catch (error) {
		console.error(error)
	}
})


// view search event
// https://developers.facebook.com/docs/meta-pixel/reference
jQuery(document).on("pmw:s2s:search", (event, payload) => {

	try {
		if (wpm.canNotFireFbq()) return

		fbq("track", "Search", payload.facebook.custom_data, {
			eventID: payload.facebook.event_id,
		})
		pmw.console.log("Facebook Pixel: Search event sent", payload.facebook)

	} catch (error) {
		console.error(error)
	}
})

// load always event
jQuery(document).on("pmw:load-always", () => {

	try {
		if (wpm.canNotFireFbq()) return

		wpm.setFbUserData()
	} catch (error) {
		console.error(error)
	}
})

// view order received page event
// https://developers.facebook.com/docs/meta-pixel/reference
jQuery(document).on("pmw:s2s:view-order-received-page", (event, payload) => {

	try {
		if (wpm.canNotFireFbq()) return

		fbq("track", "Purchase", payload.facebook.custom_data, {
			eventID: payload.facebook.event_id,
		})
		pmw.console.log("Facebook Pixel: Purchase event sent", payload.facebook)

	} catch (error) {
		console.error(error)
	}
})

document.addEventListener("pmw:consent:update", ({detail}) => {

	const consent = detail

	try {
		if (!wpmDataLayer?.pixels?.facebook?.loaded) return

		if (consent.marketing) {
			fbq("consent", "grant")
			pmw.console.log("Facebook pixel - marketing consent granted")
		} else {
			fbq("consent", "revoke")
			pmw.console.log("Facebook pixel - marketing consent revoked")
		}

	} catch (error) {
		console.error(error)
	}
}, true)
