export const loadDeprecatedFunctions = () => {

	pmw.consentAcceptAll = (data) => {
		console.error("The function pmw.consentAcceptAll is deprecated since version 1.41.1 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw.consent.api.acceptAll()")
		pmw.consent.api.acceptAll(data)
	}

	pmw.consentRevokeAll = (data) => {
		console.error("The function pmw.consentRevokeAll is deprecated since version 1.41.1 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw.consent.api.revokeAll()")
		pmw.consent.api.revokeAll(data)
	}

	pmw.consentAdjustSelectively = (data) => {
		console.error("The function pmw.consentAdjustSelectively is deprecated since version 1.41.1 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw.consent.api.adjustSelectively()")

		data = renameDataKeys(data)
		pmw.consent.api.updateSelectively(data)
	}

	/**
	 * Rename data keys
	 *
	 * The variable data can have a key analytics and a key ads
	 * they need to be rewritten to statistics and marketing
	 *
	 * @param data
	 * @returns {*}
	 */
	const renameDataKeys = (data) => {
		if (data.analytics) {
			data.statistics = data.analytics
			delete data.analytics
		}

		if (data.ads) {
			data.marketing = data.ads
			delete data.ads
		}

		return data
	}

	// wpmBeginCheckout
	jQuery(document).on("wpmBeginCheckout", () => {
		console.error("The event wpmBeginCheckout is deprecated since version 1.42.9 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:begin-checkout")
		jQuery(document).trigger("pmw:begin-checkout")
	})

	// wpmAddToCart
	jQuery(document).on("wpmAddToCart", (event, product) => {
		console.error("The event wpmAddToCart is deprecated since version 1.42.8 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:add-to-cart")
		jQuery(document).trigger("pmw:add-to-cart", product)
	})

	// wpmOrderReceivedPage
	jQuery(document).on("wpmOrderReceivedPage", () => {
		console.error("The event wpmOrderReceivedPage is deprecated since version 1.42.9 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:view-order-received-page")
		jQuery(document).trigger("pmw:view-order-received-page")
	})

	// wpmLogin
	jQuery(document).on("wpmLogin", () => {
		console.error("The event wpmLogin is deprecated since version 1.42.9 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:login")
		jQuery(document).trigger("pmw:login")
	})

	// wpmAddToWishlist
	jQuery(document).on("wpmAddToWishlist", (event, product) => {
		console.error("The event wpmAddToWishlist is deprecated since version 1.42.9 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:add-to-wishlist")
		jQuery(document).trigger("pmw:add-to-wishlist", product)
	})

	// pmwEvent:Search
	jQuery(document).on("pmwEvent:Search", () => {
		console.error("The event pmwEvent:Search is deprecated since version 1.42.9 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:search")
		jQuery(document).trigger("pmw:search")
	})

	// wpmViewItem
	jQuery(document).on("wpmViewItem", (event, product) => {
		console.error("The event wpmViewItem is deprecated since version 1.42.9 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:view-item")
		jQuery(document).trigger("pmw:view-item", product)
	})

	// wpmViewItemList
	jQuery(document).on("wpmViewItemList", (event, product) => {
		console.error("The event wpmViewItemList is deprecated since version 1.42.9 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:view-item-list")
		jQuery(document).trigger("pmw:view-item-list", product)
	})

	// wpmSelectItem
	jQuery(document).on("wpmSelectItem", (event, product) => {
		console.error("The event wpmSelectItem is deprecated since version 1.42.9 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:select-item")
		jQuery(document).trigger("pmw:select-item", product)
	})

	// wpmRemoveFromCart
	jQuery(document).on("wpmRemoveFromCart", (event, product) => {
		console.error("The event wpmRemoveFromCart is deprecated since version 1.42.9 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:remove-from-cart")
		jQuery(document).trigger("pmw:remove-from-cart", product)
	})
	
	// wpmViewCart
	jQuery(document).on("wpmViewCart", () => {
		console.error("The event wpmViewCart is deprecated since version 1.42.9 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:view-cart")
		jQuery(document).trigger("pmw:view-cart")
	})

	// wpmCategory
	jQuery(document).on("wpmCategory", (event, category) => {
		console.error("The event wpmCategory is deprecated since version 1.42.9 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:view-category")
		jQuery(document).trigger("pmw:view-category", category)
	})

	// wpmEverywhereElse
	jQuery(document).on("wpmEverywhereElse", () => {
		console.error("The event wpmEverywhereElse is deprecated since version 1.42.9 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:everywhere-else")
		jQuery(document).trigger("pmw:everywhere-else")
	})

	// wpmPlaceOrder
	jQuery(document).on("wpmPlaceOrder", () => {
		console.error("The event wpmPlaceOrder is deprecated since version 1.42.9 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:place-order")
		jQuery(document).trigger("pmw:place-order")
	})

	// wpmFireCheckoutOption
	jQuery(document).on("wpmFireCheckoutOption", (event, option) => {
		console.error("The event wpmFireCheckoutOption is deprecated since version 1.42.9 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:fire-checkout-option")
		jQuery(document).trigger("pmw:fire-checkout-option", option)
	})

	// wpmFireCheckoutProgress
	jQuery(document).on("wpmFireCheckoutProgress", (event, progress) => {
		console.error("The event wpmFireCheckoutProgress is deprecated since version 1.42.9 of the Pixel Manager and will be removed in the future. It has been replaced by: pmw:fire-checkout-progress")
		jQuery(document).trigger("pmw:fire-checkout-progress", progress)
	})
}
