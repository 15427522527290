document.addEventListener("doofinder.cart.add", async event => {

	const {item_id, amount} = event.detail

	if (!wpmDataLayer.products[item_id]) {
		await wpm.getProductsFromBackend([item_id])
	}

	if (!wpmDataLayer.products[item_id]) {
		console.log("Product not found in dataLayer or server. Exiting...")
		return
	}

	let product = wpm.getProductDetailsFormattedForEvent(item_id, amount)

	if (!product) {
		pmw.console.error("Product not found")
		return
	}

	// Trigger "pmw:add-to-cart" event using jQuery
	jQuery(document).trigger("pmw:add-to-cart", product)
})
